/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-24",
    versionChannel: "nightly",
    buildDate: "2024-01-24T00:16:08.770Z",
    commitHash: "a916f6e7a2b76fc211c568aa6b63bb9d8dc76a01",
};
